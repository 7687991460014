import { React, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Partners() {
    const [show, setShow] = useState([]);
    useEffect(() => {
        setShow(['bank-transfer', 'mastercard', 'visa']);
    }, []);
    return (
        <Container>

            <Row  className="partners-section justify-content-lg-center post-grid">
                <Col md={12}  data-aos="fade-in" data-aos-duration="500">
                    <h2 className='text-center offers ser'>
                    One of the fastest paying affiliate programs <br/> for publishers
                    </h2>
                    
                    {/* <p className='text-center'>Brands currently advertising with <span className='theme-color site-name'>LEADSBRIDGE</span></p> */}
                </Col>
                {
                    show.map((res, id)=>(
                        <Col xs={12} sm={4} md={4} lg={4} key={id} className='p-3'  data-aos="fade-up" data-aos-duration={`${id}00`}>
                            <Card style={{width:"100%", height:"100%"}}>
                                <div className='card-img-extra-paddng text-center'><Card.Img variant="top" src={`assets/img/${res}.png`} /></div>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Partners