import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import Button from 'react-bootstrap/Button';

function WhyChoose() {
    const scrollToElement = (id) => {
      const container = document.getElementById(id.substring(1));
      container.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };
  return (
    <Container>
        <Row>
            <Col md={4}>
                <sub>What We Do</sub>
                <h2>Engage Search <span className='theme-color'>Monetization Solutions</span></h2>
                <p>
                    Please choose Click Winner Search Monetization Solution that will fit your business:
                </p>
            </Col>
            <Col md={8}>
                
                <ul className='item-list-cs'>
                <li data-aos="fade-up" data-aos-duration="500">
                    <Row>
                    <Col md={2} sm={12}>
                        <div className='icon-section'><img src='./assets/img/Search Monetization.png' alt="Search Monetization"/></div>
                    </Col>
                    <Col md={10} sm={12}>
                        <h3>Search Monetization</h3>
                        <p>Click Winner Search Monetization Solution makes money with Chrome Web Store, FireFox, Opera, Internet Explorer Extensions. We have partnership with Google, Bing & Yahoo Search feed, monetizing products with Homepage, New Tab & Default Search.<br /><br /><Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="sm" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Get Started</Button></p>
                    </Col>
                    </Row>
                </li>
                <li data-aos="fade-up" data-aos-duration="500">
                    <Row>
                    <Col md={2} sm={12}>
                        <div className='icon-section'><img src='./assets/img/browser-logo.png' alt="Chrome Extension Monetization"/></div>
                    </Col>
                    <Col md={10} sm={12}>
                        <h3>Chrome Extension Monetization</h3>
                        <p>Interested to make money from your tier 1 or worldwide Chrome Browser Extension Users? We have a simple Search Monetization Solution that will generate the best revenue ever from your Chrome Web Store Extensions & Ons.<br /><br /><Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="sm" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Get Started</Button></p>
                    </Col>
                    </Row>
                </li>
                <li data-aos="fade-up" data-aos-duration="500">
                    <Row>
                    <Col md={2} sm={12}>
                        <div className='icon-section'><img src='./assets/img/software.png' alt="Software Monetization"/></div>
                    </Col>
                    <Col md={10} sm={12}>
                        <h3>Software Monetization</h3>
                        <p>Are you interested grow your business and make great revenue along the way with Your Software product? We provide the best revenue across the globe. Search Monetization.<br /><br /><Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="sm" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Get Started</Button></p>
                    </Col>
                    </Row>
                </li>
                </ul>
            </Col>
        </Row>
    </Container>
  )
}

export default WhyChoose