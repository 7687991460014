import { React } from 'react'
import Hero from './Hero';
import About from './About';
import Partners from './Partners';
import Contact from './Contact';
// import Advertising from './Advertising';
import WhyChoose from './WhyChoose';
// import Publisher from './Publisher';

function Home({ sectionRefs }) {
    
  return (
    <>
        <section className='hero' ref={sectionRefs[0]} id='home'>
        <Hero />
        </section>
        <section className='about' id="about" ref={sectionRefs[1]}>
        <About />
        </section>
        <section className='partners' id="partners" ref={sectionRefs[2]}>
        <Partners />
        </section>
        {/* <section className='advertising' id="advertising">
        <Advertising />
        </section> */}
        <section className='why-choose' id="why-choose" ref={sectionRefs[3]}>
        <WhyChoose />
        </section>
        {/* <section className='publisher' id="publisher">
        <Publisher />
        </section> */}
        <section className='contact'  id="contact" ref={sectionRefs[4]}>
        <Contact />
        </section>
    </>
  )
}

export default Home